import React from "react"
import Divider from "../Divider"

import bg from "../../images/go-burguer-bg-salada.jpg"
import * as S from "./styles"

import { saladas, adicionaisSalada } from "../../assets/cardapio-anapolis"

const CardapioSaladas = () => {
  return (
    <>
      <S.ComponentWrapper>
        <S.Container>
          <S.Title>Saladas</S.Title>

          <S.Col width="50%">
            {saladas.map((salada, index) => (
              <>
                <S.Text fontSize="16px" margin="5px 0 10px 0">
                  {salada.nome} - {salada.valor}
                </S.Text>
                <S.Text
                  fontWeight="normal"
                  fontSize="14px"
                  textTransform="initial"
                  margin="0 0 25px 0"
                >
                  ({salada.descricao})
                </S.Text>
              </>
            ))}
          </S.Col>
          <S.Col width="35%">
            <S.Text fontSize="18px" margin="24px 0 0 0" textAlign="center">
              Molhos
            </S.Text>
            <S.Text
              fontSize="14px"
              margin="0 0 20px"
              textAlign="center"
              textTransform="initial"
              fontWeight="normal"
            >
              Escolha um molho para sua salada
            </S.Text>
            <S.TextStepHolder>
              <S.Text>Tradicional</S.Text>
              <S.Text
                fontWeight="normal"
                fontSize="14px"
                textTransform="initial"
                margin="0 0 20px 0"
              >
                (Maionese caseira
                <br />
                temperada com condimentos)
              </S.Text>
              <S.Text>Picante</S.Text>
              <S.Text
                fontWeight="normal"
                fontSize="14px"
                textTransform="initial"
                margin="0 0 20px 0"
              >
                (Molho picante a base
                <br />
                de condimentos e pimenta)
              </S.Text>
              <S.Text>Rosé</S.Text>
              <S.Text
                fontWeight="normal"
                fontSize="14px"
                textTransform="initial"
                margin="0 0 20px 0"
              >
                (Catchup, maionese
                <br />e condimentos)
              </S.Text>
              <S.Text>Limão com Azeite</S.Text>
              <S.Text
                fontWeight="normal"
                fontSize="14px"
                textTransform="initial"
                margin="0 0 20px 0"
              ></S.Text>
              <S.Text>Barbecue</S.Text>
              <S.Text
                fontWeight="normal"
                fontSize="14px"
                textTransform="initial"
                margin="0 0 20px 0"
              >
                (Catchup e condimentos)
              </S.Text>
              <S.Text>Molho verde picante</S.Text>
              <S.Text
                fontWeight="normal"
                fontSize="14px"
                textTransform="initial"
                margin="0 0 20px 0"
              >
                (Maionese verde levemente picante)
              </S.Text>
              <S.Text>Tasty (NOVO)</S.Text>
              <S.Text
                fontWeight="normal"
                fontSize="14px"
                textTransform="initial"
              >
                (molho a base de condimentos levemente defumado)
              </S.Text>
            </S.TextStepHolder>
            <S.SubTitle margin="50px 0 30px">Adicionais de Carne</S.SubTitle>
            {adicionaisSalada.map((adicional, index) => (
              <S.StepHolder key={index} margin="0 0 8px 0">
                <S.TextStepHolder>
                  <S.Text>{adicional.nome}</S.Text>
                </S.TextStepHolder>
                <S.TextStepHolder>
                  <S.Text>{adicional.valor}</S.Text>
                </S.TextStepHolder>
              </S.StepHolder>
            ))}
          </S.Col>
        </S.Container>
      </S.ComponentWrapper>
      <Divider content={bg} />
    </>
  )
}

export default CardapioSaladas
