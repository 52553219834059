import React from "react"
import Divider from "../Divider"
import { useStaticQuery, graphql } from "gatsby"

import { adicionais } from "../../assets/cardapio-anapolis"
import bg from "../../images/go-burguer-bg-sanduba.jpg"
import * as S from "./styles"

const CardapioSandubasKids = () => {
  const {
    breadImage,
    hamburguerImage,
    cheeseImage,
    complementImage,
  } = useStaticQuery(
    graphql`
      query {
        breadImage: file(relativePath: { eq: "go-burguer-cardapio-pao.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        hamburguerImage: file(
          relativePath: { eq: "go-burguer-cardapio-hamburguer.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        cheeseImage: file(
          relativePath: { eq: "go-burguer-cardapio-queijo.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        complementImage: file(
          relativePath: { eq: "go-burguer-cardapio-complementos.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <>
      <S.ComponentWrapper>
        <S.Container>
          <S.Title>Menu Kids</S.Title>
          <S.SubTitle>Escolha os ingredientes</S.SubTitle>
          <S.Col width="50%">
            <S.Text fontSize="18px" margin="5px 0 0 0">
              Burger Kids (Infantil) - R$15,00
            </S.Text>
            <S.Text fontSize="16px" margin="5px 0 0 0"></S.Text>
            <S.StepHolder margin="15px 0 30px">
              <S.ImageHolder>
                <S.FoodImage
                  fluid={breadImage.childImageSharp.fluid}
                ></S.FoodImage>
              </S.ImageHolder>
              <S.TextStepHolder>
                <S.Text margin="0 0 8px 0" textAlign="right">
                  Pão macio
                </S.Text>
                <S.Text textAlign="right">Carne bovina (90g)</S.Text>
              </S.TextStepHolder>
            </S.StepHolder>
            <S.Text fontSize="16px" margin="5px 0 0 0">
              1º Passo - Escolha seu queijo:
            </S.Text>
            <S.StepHolder margin="15px 0 30px">
              <S.ImageHolder>
                <S.FoodImage
                  fluid={cheeseImage.childImageSharp.fluid}
                ></S.FoodImage>
              </S.ImageHolder>
              <S.TextStepHolder>
                <S.Text margin="0 0 8px 0" textAlign="right">
                  Cheddar
                </S.Text>
                <S.Text margin="0 0 8px 0" textAlign="right">
                  Muçarela
                </S.Text>
                <S.Text textAlign="right">Prato</S.Text>
              </S.TextStepHolder>
            </S.StepHolder>
            <S.Text fontSize="16px" margin="5px 0 0 0">
              2º Passo - Escolha seus Complementos:
            </S.Text>
            <S.StepHolder margin="15px 0 30px">
              <S.TextStepHolder>
                <S.Text margin="0 0 8px 0">Bacon</S.Text>
                <S.Text margin="0 0 8px 0">Alface</S.Text>
                <S.Text margin="0 0 8px 0">Cebola Roxa Refogada</S.Text>
                <S.Text>Tomate</S.Text>
              </S.TextStepHolder>
              <S.ImageHolder>
                <S.FoodImage
                  fluid={complementImage.childImageSharp.fluid}
                ></S.FoodImage>
              </S.ImageHolder>
            </S.StepHolder>
          </S.Col>
        </S.Container>
      </S.ComponentWrapper>
      <Divider content={bg} />
    </>
  )
}

export default CardapioSandubasKids
