import React from "react"

import * as S from "./styles"

const Aviso = () => {
  return (
    <S.ComponentWrapper>
      <S.Header>
        <span>Os preços podem sofrer variações no iFood!</span>
      </S.Header>
    </S.ComponentWrapper>
  )
}

export default Aviso
