import React from "react"
import Divider from "../Divider"
import { useStaticQuery, graphql } from "gatsby"

import { adicionais } from "../../assets/cardapio-anapolis"
import bg from "../../images/go-burguer-bg-sanduba.jpg"
import * as S from "./styles"

const CardapioSandubas = () => {
  const {
    breadImage,
    hamburguerImage,
    cheeseImage,
    complementImage,
  } = useStaticQuery(
    graphql`
      query {
        breadImage: file(relativePath: { eq: "go-burguer-cardapio-pao.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        hamburguerImage: file(
          relativePath: { eq: "go-burguer-cardapio-hamburguer.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        cheeseImage: file(
          relativePath: { eq: "go-burguer-cardapio-queijo.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        complementImage: file(
          relativePath: { eq: "go-burguer-cardapio-complementos.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <>
      <S.ComponentWrapper>
        <S.Container>
          <S.Title>Sandubas</S.Title>
          <S.SubTitle>Escolha os ingredientes</S.SubTitle>
          <S.Col width="50%">
            <S.Text fontSize="18px" margin="5px 0 0 0">
              R$19,00
            </S.Text>
            <S.Text fontSize="16px" margin="5px 0 0 0">
              1º Passo - Escolha seu pão:
            </S.Text>
            <S.StepHolder margin="15px 0 30px">
              <S.ImageHolder>
                <S.FoodImage
                  fluid={breadImage.childImageSharp.fluid}
                ></S.FoodImage>
              </S.ImageHolder>
              <S.TextStepHolder>
                <S.Text margin="0 0 8px 0" textAlign="right">
                  Crocrante Francês
                </S.Text>
                <S.Text margin="0 0 8px 0" textAlign="right">
                  Macio com gergilim
                </S.Text>
                <S.Text textAlign="right">Integral</S.Text>
              </S.TextStepHolder>
            </S.StepHolder>
            <S.Text fontSize="16px" margin="5px 0 0 0">
              2º Passo - Escolha seu Hambúrguer:
            </S.Text>
            <S.StepHolder margin="15px 0 30px">
              <S.TextStepHolder>
                <S.Text margin="0 0 8px 0">Bovino</S.Text>
                <S.Text margin="0 0 8px 0">Frango (Grelhado)</S.Text>
                <S.Text margin="0 0 8px 0">Frango (Empanado)</S.Text>
                <S.Text margin="0 0 8px 0">Kafta</S.Text>
                <S.Text>Toscana</S.Text>
              </S.TextStepHolder>
              <S.ImageHolder>
                <S.FoodImage
                  fluid={hamburguerImage.childImageSharp.fluid}
                ></S.FoodImage>
              </S.ImageHolder>
            </S.StepHolder>
            <S.Text fontSize="16px" margin="5px 0 0 0">
              3º Passo - Escolha seu queijo:
            </S.Text>
            <S.StepHolder margin="15px 0 30px">
              <S.ImageHolder>
                <S.FoodImage
                  fluid={cheeseImage.childImageSharp.fluid}
                ></S.FoodImage>
              </S.ImageHolder>
              <S.TextStepHolder>
                <S.Text margin="0 0 8px 0" textAlign="right">
                  Cheddar
                </S.Text>
                <S.Text margin="0 0 8px 0" textAlign="right">
                  Muçarela
                </S.Text>
                <S.Text textAlign="right">Prato</S.Text>
              </S.TextStepHolder>
            </S.StepHolder>
            <S.Text fontSize="16px" margin="5px 0 0 0">
              4º Passo - Escolha seus Complementos:
            </S.Text>
            <S.StepHolder margin="15px 0 30px">
              <S.TextStepHolder>
                <S.Text margin="0 0 8px 0">Bacon</S.Text>
                <S.Text margin="0 0 8px 0">Alface</S.Text>
                <S.Text margin="0 0 8px 0">Cebola Roxa Refogada</S.Text>
                <S.Text>Tomate</S.Text>
              </S.TextStepHolder>
              <S.ImageHolder>
                <S.FoodImage
                  fluid={complementImage.childImageSharp.fluid}
                ></S.FoodImage>
              </S.ImageHolder>
            </S.StepHolder>
          </S.Col>
          <S.Col width="35%">
            <S.Text fontSize="18px" textAlign="center" margin="0 0 20px 0">
              Adicionais
            </S.Text>
            {adicionais.map((adicional, index) => (
              <S.StepHolder key={index} margin="0 0 8px 0">
                <S.TextStepHolder>
                  <S.Text>{adicional.nome}</S.Text>
                </S.TextStepHolder>
                <S.TextStepHolder>
                  <S.Text>{adicional.valor}</S.Text>
                </S.TextStepHolder>
              </S.StepHolder>
            ))}
            <S.Text fontSize="18px" textAlign="center" margin="50px 0 20px 0">
              Molhos R$2,00
            </S.Text>
            <S.TextStepHolder>
              <S.Text>Tradicional</S.Text>
              <S.Text
                fontWeight="normal"
                fontSize="14px"
                textTransform="initial"
                margin="0 0 20px 0"
              >
                (Maionese caseira
                <br />
                temperada com condimentos)
              </S.Text>
              <S.Text>Picante</S.Text>
              <S.Text
                fontWeight="normal"
                fontSize="14px"
                textTransform="initial"
                margin="0 0 20px 0"
              >
                (Molho picante a base
                <br />
                de condimentos e pimenta)
              </S.Text>
              <S.Text>Rosé</S.Text>
              <S.Text
                fontWeight="normal"
                fontSize="14px"
                textTransform="initial"
                margin="0 0 20px 0"
              >
                (Catchup, maionese
                <br />e condimentos)
              </S.Text>
              <S.Text>Barbecue</S.Text>
              <S.Text
                fontWeight="normal"
                fontSize="14px"
                textTransform="initial"
                margin="0 0 20px 0"
              >
                (Catchup e condimentos)
              </S.Text>
              <S.Text>Maionese Verde Picante</S.Text>
              <S.Text
                fontWeight="normal"
                fontSize="14px"
                textTransform="initial"
                margin="0 0 20px 0"
              >
                (Maionese verde levemente picante)
              </S.Text>
              <S.Text>Tasty - (NOVO)</S.Text>
              <S.Text
                fontWeight="normal"
                fontSize="14px"
                textTransform="initial"
                margin="0 0 20px 0"
              >
                (Molho à base de condimentos levemente defumado)
              </S.Text>
            </S.TextStepHolder>
          </S.Col>
        </S.Container>
      </S.ComponentWrapper>
      <Divider content={bg} />
    </>
  )
}

export default CardapioSandubas
