export const adicionais = [
  { nome: "carne", valor: "R$8,00" },
  { nome: "queijo", valor: "R$1,50" },
  { nome: "bacon", valor: "R$2,50" },
  { nome: "tomate", valor: "R$1,00" },
  { nome: "alface", valor: "R$1,00" },
  { nome: "cebola", valor: "R$1,00" },
  { nome: "ovo", valor: "R$1,50" },
]

export const aperitivos = [
  {
    nome: "batata frita",
    descricao: "",
    conteudo: [
      { porcao: "Individual -", valor: "R$5,00" },
      { porcao: "2 pessoas -", valor: "R$9,00" },
    ],
  },
  {
    nome: "batata frita com cheddar e bacon",
    descricao: "(Batata frita com molho de chedar e bacon em cubos)",
    conteudo: [
      { porcao: "Individual -", valor: "R$5,00" },
      { porcao: "2 pessoas -", valor: "R$9,00" },
    ],
  },
  {
    nome: "batata frita com requeijão e alho frito",
    descricao: "(Batata frita com requeijão e alho granulado frito)",
    conteudo: [
      { porcao: "Individual -", valor: "R$6,00" },
      { porcao: "2 pessoas -", valor: "R$11,00" },
    ],
  },
  {
    nome: "batata frita 3 queijos e bacon",
    descricao:
      "(Batata frita com mix de chedar, muçarela e prato e bacons em cubo)",
    conteudo: [
      { porcao: "2 pessoas -", valor: "R$15,00" },
      { porcao: "", valor: "" },
    ],
  },
  {
    nome: "onion rings",
    descricao: "(Anéis de cebola empanados e fritos)",
    conteudo: [
      { porcao: "Individual -", valor: "R$7,00" },
      { porcao: "2 pessoas -", valor: "R$14,00" },
    ],
  },
  {
    nome: "chicken strip",
    descricao:
      "(Tiras de frango empanadas e frita) - Acompanha molho a sua escolha: Barbecue, Tradicional, Rosê, Picante E Maionese verde picante",
    conteudo: [
      { porcao: "2 pessoas -", valor: "R$17,00" },
      { porcao: "", valor: "" },
    ],
  },
  {
    nome: "buffalo wings",
    descricao:
      "(Coxinhas da asa. fritas, cobertas com o molho bualo, levemente picante) - Acompanha molho a sua escolha: Barbecue, Tradicional, Rosê, Picante E Maionese verde picante",
    conteudo: [
      { porcao: "2 pessoas -", valor: "R$17,00" },
      { porcao: "", valor: "" },
    ],
  },
]

export const saladas = [
  {
    nome: "ceaser",
    valor: "R$16,00",
    descricao:
      "Salada caeser a base de alface, queijo parmesão, crótons e molho caeser",
  },
  {
    nome: "salada mix",
    valor: "R$16,00",
    descricao:
      "Alface, tomate cereja, muçarela em cubos, cenoura ralada, repolho roxo e pepino - Acompanha molho de sua escolha",
  },
]

export const adicionaisSalada = [
  { nome: "filé de frango", valor: "R$5,00" },
  { nome: "contra filé grelhado", valor: "R$9,00" },
  { nome: "filé mignon grelhado", valor: "R$12,00" },
  { nome: "lombo suíno", valor: "R$7,00" },
  { nome: "chicken strip", valor: "R$6,00" },
]
