import React from "react"
import Divider from "../Divider"
import { useStaticQuery, graphql } from "gatsby"

import { adicionais } from "../../assets/cardapio-anapolis"
import bg from "../../images/go-burguer-bg-bebidas.jpg"
import * as S from "./styles"

const CardapioBebidas = () => {
  const {
    breadImage,
    hamburguerImage,
    cheeseImage,
    complementImage,
  } = useStaticQuery(
    graphql`
      query {
        breadImage: file(relativePath: { eq: "go-burguer-cardapio-pao.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        hamburguerImage: file(
          relativePath: { eq: "go-burguer-cardapio-hamburguer.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        cheeseImage: file(
          relativePath: { eq: "go-burguer-cardapio-queijo.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        complementImage: file(
          relativePath: { eq: "go-burguer-cardapio-complementos.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <>
      <S.ComponentWrapper>
        <S.Container>
          <S.Title>Bebidas</S.Title>
          <S.Col width="35%">
            <S.StepHolder margin="36px 0 8px 0">
              <S.TextStepHolder>
                <S.Text>Refrigerantes</S.Text>
                <S.Text
                  fontWeight="normal"
                  fontSize="14px"
                  textTransform="initial"
                  margin="0 0 20px 0"
                >
                  Coca-cola, Coca-cola Zero, Fanta,
                  <br />
                  Sprite, Guaraná Antarctica,
                  <br />
                  Guaraná Antarctica Zero,
                  <br />
                  H20, H20 Limoneto
                </S.Text>
              </S.TextStepHolder>
              <S.TextStepHolder>
                <S.Text>R$5,00</S.Text>
              </S.TextStepHolder>
            </S.StepHolder>
            <S.StepHolder margin="36px 0 8px 0">
              <S.TextStepHolder>
                <S.Text>Sodas italianas</S.Text>
                <S.Text
                  fontWeight="normal"
                  fontSize="14px"
                  textTransform="initial"
                  margin="0 0 20px 0"
                >
                  Soda GoBurguer Maça Verde
                  <br />
                  (Bebida refrescante feita com água com gás
                  <br />e redução de maça verde, adoçada naturalmente)
                  <br />
                  <br />
                  Soda GoBurguer Morango
                  <br />
                  (Bebida refrescante feita com água com gás
                  <br />e redução de morango, adoçada naturalmente)
                  <br />
                  <br />
                  Consulte a disponibilidade de outros sabores
                </S.Text>
              </S.TextStepHolder>
              <S.TextStepHolder>
                <S.Text>R$7,00</S.Text>
              </S.TextStepHolder>
            </S.StepHolder>
            <S.StepHolder margin="36px 0 8px 0">
              <S.TextStepHolder>
                <S.Text>Sucos - Lata</S.Text>
                <S.Text
                  fontWeight="normal"
                  fontSize="14px"
                  textTransform="initial"
                  margin="0 0 20px 0"
                >
                  Uva
                </S.Text>
              </S.TextStepHolder>
              <S.TextStepHolder>
                <S.Text>R$5,00</S.Text>
              </S.TextStepHolder>
            </S.StepHolder>
            <S.StepHolder margin="36px 0 8px 0">
              <S.TextStepHolder>
                <S.Text>Sucos - Natural (500ml)</S.Text>
                <S.Text
                  fontWeight="normal"
                  fontSize="14px"
                  textTransform="initial"
                  margin="0 0 20px 0"
                >
                  Abacaxi, Abacaxi com hortelã,
                  <br />
                  Acerola, Cajú,
                  <br />
                  Maracujá, Morango
                </S.Text>
              </S.TextStepHolder>
              <S.TextStepHolder>
                <S.Text>R$7,00</S.Text>
              </S.TextStepHolder>
            </S.StepHolder>
            <S.StepHolder margin="36px 0 8px 0">
              <S.TextStepHolder>
                <S.Text>Cervejas - Long Neck</S.Text>
                <S.Text
                  fontWeight="normal"
                  fontSize="14px"
                  textTransform="initial"
                  margin="0 0 20px 0"
                >
                  Consulte a disponibilidade de marcas
                </S.Text>
              </S.TextStepHolder>
              <S.TextStepHolder>
                <S.Text>R$8,00</S.Text>
              </S.TextStepHolder>
            </S.StepHolder>
            <S.StepHolder margin="36px 0 8px 0">
              <S.TextStepHolder>
                <S.Text>Água sem gás</S.Text>
                <S.Text
                  fontWeight="normal"
                  fontSize="14px"
                  textTransform="initial"
                  margin="0 0 20px 0"
                ></S.Text>
              </S.TextStepHolder>
              <S.TextStepHolder>
                <S.Text>R$3,00</S.Text>
              </S.TextStepHolder>
            </S.StepHolder>
            <S.StepHolder margin="36px 0 8px 0">
              <S.TextStepHolder>
                <S.Text>Água com gás</S.Text>
                <S.Text
                  fontWeight="normal"
                  fontSize="14px"
                  textTransform="initial"
                  margin="0 0 20px 0"
                ></S.Text>
              </S.TextStepHolder>
              <S.TextStepHolder>
                <S.Text>R$4,00</S.Text>
              </S.TextStepHolder>
            </S.StepHolder>
          </S.Col>
        </S.Container>
      </S.ComponentWrapper>
      <Divider content={bg} />
    </>
  )
}

export default CardapioBebidas
