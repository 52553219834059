import React from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"

import Menu from "../components/Menu"
import CardapioSandubas from "../components/CardapioSandubas"
import CardapioAperitivos from "../components/CardapioAperitivos"
import CardapioBaldeFrango from "../components/CardapioBaldeFrango"
import CardapioSaladas from "../components/CardapioSaladas"
import Aviso from "../components/Aviso"
import CardapioSandubasKids from "../components/CardapioSandubasKids"
import CardapioBebidas from "../components/CardapioBebidas"
import CardapioSobremesa from "../components/CardapioSobremesa"
import CardapioSandubasVegetariano from "../components/CardapioSandubasVegetariano"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <CardapioSandubas />
    <CardapioAperitivos />
    {/* <CardapioSobremesa /> */}
    {/* <CardapioBaldeFrango /> */}
    <CardapioSaladas />
    <CardapioSandubasKids />
    <CardapioSandubasVegetariano />
    <CardapioBebidas />
    <Aviso />
  </Layout>
)

export default IndexPage
