import styled from "styled-components"
import Img from "gatsby-image"

import bg from "../../images/go-burguer-bg-burguer-do-mes.jpg"
export const ComponentWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  font-family: "Arvo";
  background: url(${bg}) center/cover;
  z-index: 10;
`

export const Header = styled.div`
  padding: 20px 10px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  background: #7e1e17;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  @media (max-width: 600px) {
    font-size: 14px;
    padding: 20px 5px;
  }
`
