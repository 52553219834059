import React from "react"
import Divider from "../Divider"

import { aperitivos } from "../../assets/cardapio-anapolis"
import bg from "../../images/go-burguer-bg-batata.jpg"
import * as S from "./styles"

const CardapioAperitivos = () => (
  <>
    <S.ComponentWrapper>
      <S.Container>
        <S.Title>Aperitivos</S.Title>
        {aperitivos.map((aperitivo, index) => (
          <S.Col width="23%">
            <S.Text fontSize="16px" margin="24px 0 5px 0">
              {aperitivo.nome}
            </S.Text>
            <S.Text
              fontWeight="normal"
              fontSize="14px"
              textTransform="initial"
              margin="-4px 0 20px 0"
            >
              {aperitivo.descricao}
            </S.Text>
            <S.Text fontWeight="normal" fontSize="15px" textTransform="initial">
              {aperitivo.conteudo[0].porcao} {aperitivo.conteudo[0].valor}
            </S.Text>
            <S.Text fontWeight="normal" fontSize="15px" textTransform="initial">
              {aperitivo.conteudo[1].porcao} {aperitivo.conteudo[1].valor}
            </S.Text>
          </S.Col>
        ))}
      </S.Container>
    </S.ComponentWrapper>
    <Divider content={bg} />
  </>
)

export default CardapioAperitivos
