import styled from "styled-components"
import Img from "gatsby-image"

import bg from "../../images/go-burguer-pattern.png"

export const ComponentWrapper = styled.div`
  background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.9) 100%
    ),
    url(${bg});
  display: flex;
  justify-content: center;
`

export const Container = styled.div`
  margin-top: 40px;
  width: 90%;
  max-width: 1080px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const Text = styled.p`
  font-family: Arvo;
  text-transform: ${({ textTransform }) => textTransform || `uppercase`};
  font-weight: ${({ fontWeight }) => fontWeight || `bold`};
  font-size: ${({ fontSize }) => fontSize || `initial`};
  text-align: ${({ textAlign }) => textAlign || `left`};
  margin: ${({ margin }) => margin || `initial`};
  line-height: 1.2;
`

export const Title = styled.h2`
  font-family: Arvo;
  text-align: center;
  width: 100%;
  font-size: 28px;
  text-transform: uppercase;
  font-weight: bold;
`

export const SubTitle = styled(Title)`
  margin: 8px 0 40px;
  font-size: 20px;
`
export const Col = styled.div`
  width: ${({ width }) => width || `auto`};

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const StepHolder = styled.div`
  margin: ${({ margin }) => margin || `initial`};
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const ImageHolder = styled.div``

export const FoodImage = styled(Img)`
  width: 180px;
`

export const TextStepHolder = styled.div``
